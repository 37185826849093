import { getChannelInfo, getConfigurationValue } from "../../../../../../api/components/download";

const state = {
  androidMt4DownloadUrl: '',
  androidDownloadUrl: '',
  iosMt4DownloadUrl: '',
  iosMt5DownloadUrl: '',
  isAICustomService: false,
  tradeWapLink: '',
}

const mutations = {
  SET_ANDROID_URL(state, url) {
    state.androidDownloadUrl = url
  },
  SET_ANDROID_MT4_URL(state, url) {
    state.androidMt4DownloadUrl = url
  },
  SET_IOS_MT4_URL(state, url) {
    state.iosMt4DownloadUrl = url
  },
  SET_IOS_MT5_URL(state, url) {
    state.iosMt5DownloadUrl = url
  },
  SET_IS_AICUSTOMSERVICE(state, data) {
    state.isAICustomService = data
  },
  SET_TRADE_WAP_LINK(state, data) {
    state.tradeWapLink = data
  },
}

const actions = {
  async getAndroidDownloadUrl({ commit }, code = '9999') {
    const res = await getChannelInfo(code);
    const { downloadUrl = '', mt4UpdateUrl } = res.Data;
    let apkurl = downloadUrl || '';
    const { origin } = window.location;
    const absoluteURLRegex = /^[a-zA-Z]+:\/\/.+/;
    if (absoluteURLRegex.test(downloadUrl)) {
      apkurl = downloadUrl
    } else {
      apkurl = origin + '/' + downloadUrl
    }
    commit('SET_ANDROID_URL', apkurl)
    commit('SET_ANDROID_MT4_URL', mt4UpdateUrl)
  },
  async getIosDownloadUrl({ commit }, key = 'IOSDownLoadLink') {
    const res = await getConfigurationValue({key});
    if(res.Data) {
      if(key === 'IOSDownLoadLinkMT5') {
        commit('SET_IOS_MT5_URL', res.Data)
      } else {
        commit('SET_IOS_MT4_URL', res.Data)
      }
    }
  },
  async getAICustomService({ commit }) {
    const res = await getConfigurationValue({key: 'AICustomService'});
    if(res.Data === '1') {
      commit('SET_IS_AICUSTOMSERVICE', true)
    } else {
      commit('SET_IS_AICUSTOMSERVICE', false)
    }
  },
  handleDownload({ state }) {
    var u = navigator.userAgent;
    if (/(iPhone|iPad|iPod|iOS)/i.test(u)) { //苹果
      try {
        window.webkit.messageHandlers.gotoRegisterReal.postMessage('1');
      } catch (error) {
        window.open(state.iosMt5DownloadUrl);
      }
    } else { // 安卓
      try {
        window.android.gotoRegisterReal();
      } catch (error) {
        window.open(state.androidDownloadUrl);
      }
    }
  },
  async getTradeWapLink({ commit, state }) {
    if(state.tradeWapLink) {
      return state.tradeWapLink
    }
    try {
      const res = await getConfigurationValue({key: 'H5LinkMT5'});
      const defaultLink = 'https://h5pro.rlc561.com';
      if(res.Data) {
        commit('SET_TRADE_WAP_LINK', res.Data)
        return res.Data
      } else {
        return defaultLink
      }
    } catch (e) {
      return defaultLink
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}