<template>
  <div id="box">
    <div class="top">
      <div class="topBox">
        <div></div>
        <div class="tbCenter">
          <p @click="handleTrade">
            <img src="../../static/index/trade_icon.png" width="16px" alt="" style="margin-top: 1px;">
            <span>网页版交易</span>
          </p>
          <p @click="notice('1-1')">
            <img src="../../static/index/voice.png" alt="">
            <span>平台公告</span>
          </p>
          <p>
            <img src="../../static/index/phone.png" alt="">
            <span>APP下载</span>
            <ul class="codeBox">
              <ul class="codeBox-left">
                <p>下载MT4账户专用版本</p>
                <ul class="codeBox-code">
                  <li v-if="showIos">
                    <Qrcode :link="iosMt4DownloadUrl" :size="110" :iconSize="30"/>
                    <span style="margin-top: -4px;">IOS</span>
                  </li>
                  <li>
                    <Qrcode :link="androidMt4DownloadUrl" :size="100"/>
                    <span>Android</span>
                  </li>
                </ul>
              </ul>
              <ul class="codeBox-right">
                <p>下载MT5账户专用版本</p>
                <ul class="codeBox-code">
                  <li v-if="showIos">
                    <Qrcode :link="iosMt5DownloadUrl" :size="110" :iconSize="30"/>
                    <span style="margin-top: -4px;">IOS</span>
                  </li>
                  <li>
                    <Qrcode :link="androidDownloadUrl" :size="100"/>
                    <span>Android</span>
                  </li>
                </ul>
              </ul>
              <p class="codeBox-tips">如无法确定所用账户的MT版本，可联系在线客服查询。</p>
            </ul>
            
            <ul class="codeBox2">
              <ul class="codeBox-left">
                <p>下载MT4账户专用版本</p>
                <ul class="codeBox-code">
                  <li>
                    <a :href="iosMt4DownloadUrl">
                      <img src="../../static/index/btn_ios.png" alt="">
                    </a>
                  </li>
                  <li>
                    <a @click="handleDownload(androidMt4DownloadUrl)">
                      <img src="../../static/index/btn_android.png" alt="">
                    </a>
                  </li>
                </ul>
              </ul>
              <ul class="codeBox-right">
                <p>下载MT5账户专用版本</p>
                <ul class="codeBox-code">
                  <li>
                    <a :href="iosMt5DownloadUrl">
                      <img src="../../static/index/btn_ios.png" alt="">
                    </a>
                  </li>
                  <li>
                    <a @click="handleDownload(androidDownloadUrl)">
                      <img src="../../static/index/btn_android.png" alt="">
                    </a>
                  </li>
                </ul>
              </ul>
              <p class="codeBox-tips">如无法确定所用账户的MT版本，可联系在线客服查询。</p>
              <!-- <li>
                 <a href="https://apps.apple.com/cn/app/id1602800589">
                  <img src="../../static/index/btn_ios.png" alt="">
                 </a>
                 
              </li>
              <li>
                <a :href="androidDownloadUrl">
                  <img src="../../static/index/btn_android.png" alt="">
                 </a>
                 
              </li> -->
            </ul>
          </p>
        </div>
      </div>
    </div>
    <div class="pcBox" :class="navBarFixed == true ? 'navBarWrap' :''">
      <div class="header">
      <div class="logo_img" @click="logoClick">
        <div style="display:flex; align-items: center; float:left; height: 80px;">
          <div class="header-logo">
            <img id="header-img" src="../../static/common/logo.png">
          </div>
        </div>
      </div>
      <div id="menu_index">
        <!-- web端菜单导航 -->
        <el-menu 
          v-if="windowWidth >= 500" 
          :default-active="activeIndex" 
          class="el-menu-demo" 
          menu-trigger="click" 
          text-color="#474747"
          style="height: 80px; border:0;" 
          mode="horizontal" 
          @select="handleSelect">
          <!-- <el-submenu index="1" class="header-title">
            <template slot="title">关于我们</template>
            <el-menu-item index="1-1">平台公告</el-menu-item>
          </el-submenu> -->
          <el-menu-item index="1" class="header-title aboutus">
            <span>关于我们</span>
            <img v-if="activeIndex == 1" src="../../static/index/selectRed.png" alt="">
            <div style="z-index:999" class="menuBox">
              <p @click.stop="notice('1-1')">平台公告</p>
            </div>
          </el-menu-item>
          <el-menu-item index="2" class="header-title aboutus">
            <span>关于产品</span>
            <img v-if="activeIndex == 2" src="../../static/index/selectRed.png" alt="">
            <div style="z-index:999" class="menuBox">
              <p @click.stop="notice('2-1')">产品介绍</p>
              <p @click.stop="notice('2-2')">账户介绍</p>
              <p @click.stop="notice('2-3')">产品细则</p>
              <p @click.stop="notice('2-4')">今日汇率</p>
            </div>
          </el-menu-item>
          <el-menu-item index="8" class="header-title">
            <span>立即交易</span>
            <img v-if="activeIndex == 8" src="../../static/index/selectRed.png" alt="">
          </el-menu-item>
          <el-menu-item index="3" class="header-title aboutus">
            <span>平台下载</span>
            <img v-if="activeIndex == 3" src="../../static/index/selectRed.png" alt="">
            <div style="z-index:999" class="menuBox">
              <p @click.stop="handleTrade()">PC网页版</p>
              <p @click.stop="notice('3-1')">APP下载</p>
              <p @click.stop="notice('3-2')">MT4下载</p>
              <p @click.stop="notice('3-3')">MT5下载</p>
            </div>
          </el-menu-item>
          <el-menu-item index="4" class="header-title aboutus">
            <span>行情资讯</span>
            <img v-if="activeIndex == 4" src="../../static/index/selectRed.png" alt="">
            <div style="z-index:999" class="menuBox">
              <p @click.stop="notice('4-1')">财经日历</p>
              <p @click.stop="notice('4-2')">热点新闻</p>
              <p @click.stop="notice('4-3')">市场快讯</p>
              <p @click.stop="notice('4-4')">专家评论</p>
              <p @click.stop="notice('4-5')">红狮课堂</p>
            </div>
          </el-menu-item>
          <el-menu-item index="5" class="header-title">
            <span>数据中心</span>
            <img v-if="activeIndex == 5" src="../../static/index/selectRed.png" alt="">
          </el-menu-item>
          <el-menu-item index="6" class="header-title">
            <span>用户中心</span>
            <img v-if="activeIndex == 6" src="../../static/index/selectRed.png" alt="">
          </el-menu-item>
          <el-menu-item index="7" class="header-title">
            <span>联系客服</span>
            <img v-if="activeIndex == 7" src="../../static/index/selectRed.png" alt="">
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">
      <p style="font-size: 0.625em; font-weight: bold">TOP</p>
      <!-- <img src="../assets/other/launch.png" style="height: 45px;width: 35px" /> -->
    </div>
    </div>
    <div class="wapTopBox">
      <img src="../../static/common/logo.png" @click="logoClickWap" alt="">
      <img class="menuImg" src="../../static/common/menu.png" @click="controlMenuShow" alt="">
    </div>
    <div class="wapBox">
      <van-popup
        id="drawer"
        v-model="show"
        closeable
        close-icon-position="top-right"
        position="right"
        @opened="openPopup"
        @click-close-icon="closerPopup"
        :style="{ height: '100%', width: '100%' }">
        <div class="menuBox">
          <div class="logoBox">
            <img v-if="!navBarFixed" @click="logoClickWap" id="header-img" src="../../static/common/logo.png">
            <img v-if="navBarFixed" id="header-img" src="../../static/common/logowhite.png" alt="">
          </div>
          <el-col>
            <el-menu
              :default-active="controlMenuClass"
              :default-openeds="defaultWapMenu"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#ffffff"
              active-text-color="#d52226"
              unique-opened
              ref="kzMenu"
              @select="handleSelect">
              <el-submenu index="1">
                <template slot="title">
                  <span @click="menuAboutus(1)">关于我们</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-1">平台公告</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <!-- <el-menu-item index="2">
                <span slot="title">关于产品</span>
              </el-menu-item> -->
              <el-submenu index="2">
                <template slot="title">
                  <span @click="menuAboutus(2)">关于产品</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="2-1">产品介绍</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="2-2">账户介绍</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="2-3">产品细则</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="2-4">今日汇率</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="3">
                <template slot="title">
                  <span @click="menuAboutus(3)">平台下载</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="3-1">APP下载</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="3-2">MT4下载</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="3-3">MT5下载</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="4">
                <template slot="title">
                  <span @click="menuAboutus(4)">行情资讯</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="4-1">财经日历</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="4-2">热点新闻</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="4-3">市场快讯</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="4-4">专家评论</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="4-5">红狮课堂</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item index="5">
                <span slot="title">数据中心</span>
              </el-menu-item>
              <el-menu-item index="6">
                <span slot="title">用户中心</span>
              </el-menu-item>
              <el-menu-item index="7">
                <span slot="title">联系客服</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </div>
      </van-popup>
    </div>
    <HumanService/>
  </div>
</template>

<script>
  import eventBusMitter from '../../utils/eventbus';
  import { mapState  } from "vuex";
  import Qrcode from './QrCode/index.vue'
  import HumanService from './HumanService/index.vue'
  import { handleMT5Trade } from '../../utils/tools'
  export default {
    name: "Layout",
    // props:['getSessinKey'],
    components: {
      Qrcode,
      HumanService,
    },
    data() {
      return {
        activeIndex: 0,
        scroll: '',
        showIos:true,
        timer: '',
        seen: true,
        currentMenu: '关于我们',
        navBarFixed: false,
        windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
        // 控制wap-menu的显示与隐藏
        show: false,
        // 控制wap-menu菜单显示哪一个
        controlMenuClass:'',
        // 控制wap-menu菜单展开哪个
        defaultWapMenu:[]
      };
    },
    created(){
      const Rhref = window.location.host;
      if(Rhref.indexOf('rlchltd')>-1){
        // this.showIos = false
      }else{
        this.showIos = true
      }
      if(!sessionStorage.getItem('key')){
        this.activeIndex = '0';
      }else{
        this.activeIndex = sessionStorage.getItem('key');
      }
      this.$emit('getKey', this.activeIndex);
      // 接收eventBus传来的值
      eventBusMitter.on('eventBus',(res)=>{
        this.activeIndex = res.key;
      })
      eventBusMitter.on('appDownload',(res)=>{
        this.activeIndex = res.key;
      })
    },
    mounted() {
     
      var that = this;
      // <!--把window.onresize事件挂在到mounted函数上-->
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.windowWidth = window.fullWidth; // 宽
        })()
      };
    },
    computed: {
      ...mapState('common', {
        androidMt4DownloadUrl: 'androidMt4DownloadUrl',
        androidDownloadUrl: 'androidDownloadUrl',
        iosMt4DownloadUrl: 'iosMt4DownloadUrl',
        iosMt5DownloadUrl: 'iosMt5DownloadUrl',
      })
    },
    watch: {
      windowWidth (val) {
        let that = this;
        console.log("实时屏幕宽度：",val, that.windowWidth );
      },
    },
    methods: {
      // pc端menu
      handleSelect(key) {
        this.$emit('getKey', key);
        sessionStorage.setItem('key',key);
        // this.getSessinKey(key);
        switch (key) {
          case '1':
            this.controlMenuClass = key;
            this.activeIndex = key;
            this.$router.push({
              name: "Aboutus"
            });
            this.show = false;
            break;
          case '2':
            this.controlMenuClass = key;
            this.activeIndex = key;
            this.$router.push({
              name:'Product'
            })
            this.show = false;
            break;
          case '3':
            this.controlMenuClass = key;
            this.activeIndex = key;
            this.$router.push({
              name: "Appdownload"
            });
            this.show = false;
            break;
          case '4':
            this.controlMenuClass = key;
            this.activeIndex = key;
            this.$router.push({
              name: "Market"
            });
            this.show = false;
            break;
          case '5':
            this.controlMenuClass = key;
            this.activeIndex = key;
            this.$router.push({
              name: "Details"
            });
            this.show = false;
            break;
          case '6':
            this.controlMenuClass = key;
            this.activeIndex = key;
            let domin = document.domain;
            console.log(domin);
            window.open('https://' + domin + '/uc/userCenter');
            this.show = false;
            break;
          case '7':
            this.controlMenuClass = key;
            this.activeIndex = key;
            // window.open('https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377');
            window.open(this.$parent.live800UrlUc)
            this.show = false;
            break;
          // 移动端menu
          case '1-1':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Notice"
            });
            this.show = false;
            break;
          case '2-1':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Product",
              query:{
                key:1,
              }
            });
            this.show = false;
            break;
          case '2-2':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Product",
              query:{
                key:2,
              }
            });
            this.show = false;
            break;
          case '2-3':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Product",
              query:{
                key:3,
              }
            });
            this.show = false;
            break;
          case '2-4':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Product",
              query:{
                key:4,
              }
            });
            this.show = false;
            break;
          case '3-1':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Appdownload",
              query:{
                key:1,
              }
            });
            this.show = false;
            break;
          case '3-2':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Appdownload",
              query:{
                key:2,
              }
            });
            this.show = false;
            break;
          case '3-3':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Appdownload",
              query:{
                key:3,
              }
            });
            this.show = false;
            break;
          case '4-1':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Market",
              query:{
                key:1,
              }
            });
            this.show = false;
            break;
          case '4-2':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Market",
              query:{
                key:2,
              }
            });
            this.show = false;
            break;
          case '4-3':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Market",
              query:{
                key:3,
              }
            });
            this.show = false;
            break;
          case '4-4':
            this.controlMenuClass = key;
            this.$router.push({
              name: "Market",
              query:{
                key:4,
              }
            });
            this.show = false;
            break;
          case '4-5':
            this.activeIndex = '5';
            this.controlMenuClass = key;
            this.$router.push({
              name: "classRoom",
            });
            this.show = false;
            break;
          case '8':
            this.activeIndex = '8';
            this.handleTrade()
            break;
        }
      },
      // 
      handleTrade() {
        handleMT5Trade()
      },
      // 点击跳转到子菜单
      notice(key){
        console.log(key);
        switch(key){
          case '1-1':
            this.activeIndex = '1';
            this.$router.push({
              name: "Notice"
            });
            this.show = false;
            break;
          case '2-1':
            this.activeIndex = '2';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Product",
              query:{
                key:1,
              }
            });
            this.show = false;
            break;
          case '2-2':
            this.activeIndex = '2';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Product",
              query:{
                key:2,
              }
            });
            this.show = false;
            break;
          case '2-3':
            this.activeIndex = '2';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Product",
              query:{
                key:3,
              }
            });
            this.show = false;
            break;
          case '2-4':
            this.activeIndex = '2';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Product",
              query:{
                key:4,
              }
            });
            this.show = false;
            break;
          case '3-1':
            this.activeIndex = '3';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Appdownload",
              query:{
                key:1,
              }
            });
            this.show = false;
            break;
          case '3-2':
            this.activeIndex = '3';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Appdownload",
              query:{
                key:2,
              }
            });
            this.show = false;
            break;
          case '3-3':
            this.activeIndex = '3';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Appdownload",
              query:{
                key:3,
              }
            });
            this.show = false;
            break;
          case '4-1':
            this.activeIndex = '4';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Market",
              query:{
                key:1,
              }
            });
            this.show = false;
            break;
          case '4-2':
            this.activeIndex = '4';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Market",
              query:{
                key:2,
              }
            });
            this.show = false;
            break;
          case '4-3':
            this.activeIndex = '4';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Market",
              query:{
                key:3,
              }
            });
            this.show = false;
            break;
          case '4-4':
            this.activeIndex = '4';
            this.controlMenuClass = key;
            this.$router.push({
              name: "Market",
              query:{
                key:4,
              }
            });
            this.show = false;
            break;
          case '4-5':
            this.activeIndex = '5';
            this.controlMenuClass = key;
            this.$router.push({
              name: "classRoom",
            });
            this.show = false;
            break;
        }
      },
      // 点击一级菜单
      menuAboutus(key){
        switch (key){
          case 1 :
            this.$router.push({
              name: "Aboutus"
            });
            this.show = false;
            this.activeIndex = '1';
            break;
          case 2 :
            this.$router.push({
              name: "Product"
            });
            this.show = false;
            this.activeIndex = '2';
            break;
          case 3 :
            this.$router.push({
              name: "Appdownload"
            });
            this.show = false;
            this.activeIndex = '3';
            break;
          case 4 :
            this.$router.push({
              name: "Market"
            });
            this.show = false;
            this.activeIndex = '4';
            break;
        }
      },
      handleScroll() {
        this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
        if(this.scroll > 600) { //判断滚动后高度超过400px,就显示
          // this.$refs.btn.style.display = 'block';
        } else {
          // this.$refs.btn.style.display = 'none'
        }
        if(this.scroll > 90 ){
          this.navBarFixed = true;
        }else{
          this.navBarFixed = false;
        }
      },
      backTop() { //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
        this.timer = setInterval(() => {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          if (osTop === 0) {
            clearInterval(this.timer)
          }
        }, 10)
      },
      // 点击logo跳转到首页-web
      logoClick() {
        this.$router.push({
          name: "Index"
        });
        this.activeIndex = '0';
        document.getElementsByClassName("is-active")[0].style.color = '#474747';
      },
      // 点击logo跳转到首页-wap
      logoClickWap() {
        this.$router.push({
          name: "Index"
        });
        this.activeIndex = '0';
        this.show = false;
        // 强制控制当跳到首页时其他菜单变默认
        this.$refs.kzMenu.activeIndex = '0';
        this.defaultWapMenu = [];
        document.getElementsByClassName("is-active")[0].style.color = '#474747';
      },
      // 打开弹出层且动画结束后触发
      openPopup(){
        // this.$refs.navBarFixed.style.height = '100%'
        // document.getElementsByClassName("navBarWrap")[0].style.height = '100%';
      },
      // 点击关闭弹出层图片时触发
      closerPopup(){
        // document.getElementsByClassName("navBarWrap")[0].style.height = '';
        // this.$refs.navBarFixed.style.height = ''
      },
      // 控制mune弹出层
      controlMenuShow(){
        this.show = true;
        // console.log(document.getElementsByClassName("van-icon")[0]);
      },
      // wap-el-menu打开
      handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      // wap-el-menu关闭
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleDownload(link) {
        const userAgent = navigator.userAgent;
        const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; //android终端
        if(isAndroid && window.android) {
          window.android.onOpenUrl(link);
        } else {
          window.open(link);
        }
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
  }
</script>

<style lang="less" scoped>
  #box{
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    .top{
      width: 100%;
      height: 52px;
      background-color: #eeeff1;
      .topBox{
        max-width: 1416px;
        height: 52px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tbCenter{
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 0 20px;
            cursor: pointer;
            span{
              color: #999999;
              font-size: 13px;
              margin-left: 8px;
            }
          }
          p:nth-child(3){
            padding-right: 0;
            position: relative;
            .codeBox, .codeBox2 {
              position: absolute;
              top: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 999;
              background-color: #ffffff;
              box-sizing: border-box;
              border-radius: 10px;
              box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
              display: none;
              padding-bottom: 65px;
              &-code {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px;
                li{
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                  align-items: center;
                  img{
                    width:90px;
                    height: auto;
                    padding: 5px;
                  }
                }
                li:nth-child(1){
                  margin-right: 20px;
                }
              }
              .codeBox-left {
                border-right: 1px solid #f1f1f1;
              }
              .codeBox-left, .codeBox-right {
                padding: 20px 30px;
                border-bottom: 1px solid #f1f1f1;
              }
              &-tips {
                font-size: 14px;
                position: absolute;
                bottom: 15px;
                left: 20px;
                right: 20px;
              }
            }
          }
          .codeBox2{
            display: none;
          }
          .codeBox {
            // display: flex !important;
          }
          p:nth-child(3):hover .codeBox{
            display: flex;
          }
          
        }
      }
    }
    .wapBox{
      display: none;
    }
    .wapTopBox{
      display: none;
    }
  }
  .el-menu-item{
    height: 80px;
  }
  .logo_img {
    float: left;
    cursor: pointer;
  }
  .header-title {
    font-size: 1em;
  }

  .footer_font {
    font-size: 1em;
  }

  #menu_index {
    height: 100%;
  }
  /deep/ .el-menu--horizontal{
    background-color: #ffffff;
  }
  /deep/ .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
    background-color: #ffffff;
  }

  .header {
    margin: auto;
    max-width: 1416px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #header-img {
    width: 130px;
    height: auto;
    float: left;
    margin: 0 auto;
  }

  .header-logo {
    width: 130px;
    height: auto;
  }

  .header-important {
    float: left;
    margin-left: 6px;
    align-self: center;
  }

  .footer {
    display: flex;
    align-items: center;
    margin: 10px auto;
    width: 90%;
  }
  /deep/ .el-menu--horizontal>.el-menu-item.is-active{
    color: #d42426;
    border-bottom:0px solid #ffffff;
  }
  /deep/ .el-menu-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .el-menu-item img{
    position: absolute;
    bottom:0px;
  }
  /deep/ .el-menu.el-menu--horizontal { // 去掉element-ui导航栏底部边框线
     border-bottom: none;
  }
  .header-title:focus {
    outline: none;
  }
  .navBarWrap {
    width: 100%;
    position:fixed;
    top:0;
    left: 50%;
    transform: translate(-50%,0);
    z-index:999;
    background-color: #ffffff;
    // transition: all .5s;
  }
  .aboutus{
    position: relative;
    .menuBox{
      position: absolute;
      width: 100%;
      height: auto;
      background-color: #ffffff;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      top: 80px;
      left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      display: none;
      // box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
      p{
        color: #474747;
      }
      P:hover{
        color: #d42426;
      }
    }
  }
  .aboutus:hover .menuBox{
    display: flex;
  }

@media screen and (max-width: 1600px) {
  .header[data-v-005bfc5a]{
    max-width: 1310px;
  }
  #box .top .topBox[data-v-005bfc5a]{
    max-width: 1310px;
  }
  #box .top .topBox .tbCenter p:nth-child(3) ul[data-v-005bfc5a]{
    right: 0px;
  }
}

@media screen and (max-width: 500px) {
  #box{
    .top{
      box-sizing: border-box;
      padding-right: 10px;
      height: 35px;
      .topBox{
        width: 100% !important;
        height: 100%;
        .tbCenter{
          > p:first-child {
            display: none;
          }
          p:nth-child(3){
            .codeBox{
              display: none;
            }
            .codeBox2{
              display: none;
              width: 220px;
              padding: 0px;
              right: -10px !important;
              border-radius: 5px;
              .codeBox-code {
                display: block;
                padding-bottom: 10px;
                li{
                  text-align: center;
                  display: block;
                  img{
                    width: 160px;
                  }
                  span{
                    margin: 0;
                  }
                }
                li:nth-child(1){
                  margin: 0;
                }
              }
              .codeBox-left {
                border-right: none;
              }
              .codeBox-left, .codeBox-right {
                padding: 0;
                border-bottom: 1px solid #f1f1f1;
                >p {
                  margin: 15px 0 10px;
                  font-size: 15px;
                }
              }
              .codeBox-tips {
                font-size: 13px;
                position: relative;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 15px 20px;
              }
            }
          }
          p:nth-child(3):hover .codeBox2{
            display: block;
          }
          p:nth-child(3):hover .codeBox{
            display: none;
          }
        }
      }
    }
    .pcBox{
      display: none;
    }
    .wapBox{
      display: block;
      width: 100%;
      .menuBox{
        .logoBox{
          height: 45px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          #header-img{
            width: 100px;
            height: auto;
          }
        }
        .el-menu-item{
          height: 56px;
          justify-items: flex-start;
          align-items: flex-start;
        }
      }
      /deep/.van-popup__close-icon--top-right{
        top: 12px;
      }
    }
    .wapTopBox{
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 10px;
      img:nth-child(1){
        width: 110px;
        height: auto;
      }
      img:nth-child(2){
        width: 22px;
        height: auto;
      }
    }
  }
}

</style>