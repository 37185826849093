<template>
  <canvas class="canvasCode" ref="qrcodeCanvas" :style="`width: ${size}px;height: ${size}px`"></canvas>
</template>
<script>
import { generateQRCode } from "../../../utils/tools";

export default {
  props: {
    link: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 130
    },
    iconSize: {
      type: Number,
      default: 40
    },
    margin: {
      type: Number,
      default: 4
    },
  },
  watch: {
    link: {
      handler() {
        if(this.link) {
          generateQRCode(this.link, this.$refs.qrcodeCanvas, this.iconSize, this.margin)
        }
      },
      immediate: true
    },
  },
  mounted() {
    generateQRCode(this.link, this.$refs.qrcodeCanvas, this.iconSize, this.margin)
  },
  methods: {

  },
}
</script>